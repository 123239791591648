<template>
  <component :is="'script'" v-html="jsonld" type="application/ld+json" />
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'JsonLdFilters',
  props: {
    currentCategoryId: {
      type: Number,
      required: true
    },
    availableFilters: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getCurrentRoute: 'url/getCurrentRoute',
      baseUrl: 'siteData/baseUrl',
      getCurrentCategory: 'category/getCurrentCategory',
      getAvailableFiltersFrom: 'category-extension/getAvailableFiltersFrom',
      getCurrentFilters: 'category-extension/getCurrentFilters',
      getSystemFilterNames: 'category-next/getSystemFilterNames'
    }),
    ...mapState({
      getAggregations: state => state['category-extension'].aggregations,
      getAttributeListByCode: state => state['attribute'].list_by_code,
      getFiltersMap: state => state['category-extension'].filtersMap
    }),
    filterLabels () {
      if (!this.getAttributeListByCode) return {};

      const attrArr = Object.values(this.getAttributeListByCode);
      const labels = {};

      attrArr.forEach(item => {
        if (item && item.attribute_code) {
          labels[item.attribute_code] = item.frontend_label || '';
        }
      });

      // Додаємо спеціальні лейбли
      labels.price = 'Price';

      return labels;
    },
    jsonld () {
      return JSON.stringify(this.content, null, 2);
    },
    content () {
      if (!this.availableFilters || Object.keys(this.availableFilters).length === 0) {
        return {
          '@context': 'https://schema.org/',
          '@type': 'FilterList',
          'numberOfItems': 0,
          'itemListElement': []
        };
      }

      const filterGroups = Object.entries(this.availableFilters).map(([filterType, filters]) => {
        // Обробка фільтра ціни
        if (filterType === 'price') {
          const priceFilter = this.getPrice();
          if (!priceFilter.from && !priceFilter.to) return null;

          return {
            '@type': 'FilterGroup',
            'name': this.filterLabels[filterType] || 'Price',
            'url': `${this.baseUrl}${this.getCurrentRoute.path}`,
            'filterValues': [{
              '@type': 'FilterValue',
              'name': `${priceFilter.from} - ${priceFilter.to}`,
              'value': `${priceFilter.from}-${priceFilter.to}`,
              'url': `${this.baseUrl}${this.getCurrentRoute.path}?price=${priceFilter.from}-${priceFilter.to}`
            }]
          };
        }

        // Обробка інших фільтрів
        if (!Array.isArray(filters) || !filters.length) return null;

        return {
          '@type': 'FilterGroup',
          'name': this.filterLabels[filterType] || filterType,
          'url': `${this.baseUrl}${this.getCurrentRoute.path}`,
          'filterValues': filters.map(filter => ({
            '@type': 'FilterValue',
            'name': filter.label || '',
            'value': filter.id || '',
            'count': filter.count || 0,
            'url': `${this.baseUrl}${this.getCurrentRoute.path}?${filterType}=${filter.id}`
          }))
        };
      }).filter(Boolean);

      return {
        '@context': 'https://schema.org/',
        '@type': 'FilterList',
        'numberOfItems': filterGroups.length,
        'itemListElement': filterGroups
      };
    }
  },
  methods: {
    getPrice () {
      const { agg_price } = this.getAggregations || {};
      if (!agg_price?.filter?.price_min?.value || !agg_price?.filter?.price_max?.value) return {};

      return {
        from: Math.trunc(agg_price.filter.price_min.value),
        to: Math.trunc(agg_price.filter.price_max.value)
      };
    }
  }
}
</script>
